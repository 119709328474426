import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Quad Escursioni
			</title>
			<meta name={"description"} content={"IL TUO PERCORSO VERSO AVVENTURE ED ESPERIENZE INDIMENTICABILI DAL NOLEGGIO QUAD."} />
			<meta property={"og:title"} content={"Quad Escursioni"} />
			<meta property={"og:description"} content={"IL TUO PERCORSO VERSO AVVENTURE ED ESPERIENZE INDIMENTICABILI DAL NOLEGGIO QUAD."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1496521061024-90e1c1221555?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1496521061024-90e1c1221555?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1496521061024-90e1c1221555?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1496521061024-90e1c1221555?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1496521061024-90e1c1221555?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1496521061024-90e1c1221555?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1496521061024-90e1c1221555?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="120px 0 120px 0"
			lg-margin="0 0 0 0"
			lg-padding="80px 0 80px 0"
			background="--color-lightD1"
			quarkly-title="Price-20"
		>
			<Text
				font="--headline2"
				color="--darkL1"
				lg-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 10px 0px"
				margin="0 auto 3rem auto"
				width="50%"
				md-width="100%"
				md-margin="0 0 20px 0"
				text-align="center"
			>
				I nostri servizi di prima classe
			</Text>
			<Box padding="0px 120px 0px 120px" lg-flex-direction="column" lg-padding="0px 0 0px 0">
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Escursioni
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Guide esperte: le nostre guide esperte garantiranno un'avventura sicura e coinvolgente.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Itinerari personalizzati: adatta la tua esperienza in base al tuo livello di avventura e ai tuoi interessi.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Viaggi autonomi
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Strumenti di navigazione di prima classe: viaggia con fiducia grazie ai nostri moderni sistemi GPS.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Libertà di esplorare: esplora i sentieri al tuo ritmo e secondo il tuo programma.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Formazione sulla sicurezza
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Briefing completi: scopri tutto quello che devi sapere sulla sicurezza e il funzionamento dei quad.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Briefing completi: scopri tutto quello che devi sapere sulla sicurezza e il funzionamento dei quad.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Organizzazione di eventi
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Occasioni speciali: perfetto per eventi di gruppo, dalle uscite aziendali alle riunioni di famiglia.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Pacchetti all-inclusive: goditi un evento completamente organizzato con catering e attrezzatura.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Noleggio attrezzatura
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Attrezzature di alta qualità: dai caschi alle protezioni, ti forniremo tutto ciò di cui hai bisogno.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Varietà di opzioni: attrezzature adatte a tutte le condizioni meteorologiche e tipi di guida.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});